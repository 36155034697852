<template>
  <div class="data-live">
    <pageTitle :pageTitle="$route.meta.title">
      <div slot="content">
        1.当日数据更新频率10分钟，建议活动结束后10分钟查看完整数据
        <br />
        2.控制台数据统计为真实数据，不统计虚拟数据
      </div>
    </pageTitle>
    <div
      class="box-card"
      v-loading="loading"
      element-loading-text="加载中，请稍候"
      element-loading-background="rgba(255,255,255,.9)"
    >
      <div class="search">
        <span class="search-label">活动选择</span>
        <vh-select round v-model="selectKey" @change="changeSelect" class="vh-data-select">
          <vh-option
            v-for="item in selectOptions"
            :key="'v_' + item.id"
            :label="item.label"
            :value="item.id"
          ></vh-option>
        </vh-select>
        <search-date
          v-show="selectKey == 1"
          ref="searchDateRef"
          @changeDate="searchTimeList"
        ></search-date>
        <search-input
          v-show="selectKey != 1"
          @changeContent="searchTableList"
          :searchType="selectKey"
        ></search-input>
        <vh-button
          v-if="!showWatchDurationDatePicker"
          class="btn-watch-duration"
          icon="vh-icon-plus"
          type="text"
          @click="showWatchDurationDatePicker = true"
        >
          观看时间
        </vh-button>
        <span v-if="showWatchDurationDatePicker" class="search-label">观看时间</span>
        <div v-if="showWatchDurationDatePicker" class="data-search">
          <vh-date-picker
            round
            v-model="watchDuration"
            value-format="yyyy-MM-dd"
            type="daterange"
            unlink-panels
            @change="getTableList('search')"
            @focus="watchDurationFocus"
            range-separator="至"
            start-placeholder="开始日期"
            prefix-icon="iconfont-v3 saasicon_date"
            end-placeholder="结束日期"
            :picker-options="watchDurationPickerOptions"
            style="width: 240px; margin-right: 16px"
            popper-class="watch-duration-popper"
          ></vh-date-picker>
          <i
            @click="clearWatchDuration"
            class="vh-input__icon vh-range__close-icon vh-icon-close empty-close-icon"
          ></i>
        </div>
        <div class="export-data">
          <vh-button
            plain
            round
            size="medium"
            @click="exportCenterData"
            :disabled="!tableList.length"
          >
            导出数据
          </vh-button>
        </div>
      </div>
      <table-list
        ref="tableList"
        :manageTableData="tableList"
        :tabelColumnLabel="tableColumn"
        :tableRowBtnFun="tableRowBtnFun"
        :isCheckout="isCheckout"
        :totalNum="totalNum"
        :width="240"
        @onHandleBtnClick="onHandleBtnClick"
        @getTableList="getTableList"
      ></table-list>
      <div class="no-live" v-show="!totalNum">
        <noData :nullType="'noData'" :text="'暂无数据'"></noData>
      </div>
    </div>
  </div>
</template>

<script>
  import PageTitle from '@/components/PageTitle';
  import noData from '@/views/PlatformModule/Error/nullPage';
  import { sessionOrLocal } from '@/utils/utils';
  import searchDate from './components/searchDate';
  import searchInput from './components/searchInput';

  export default {
    name: 'dataLive',
    components: {
      PageTitle,
      noData,
      searchDate,
      searchInput
    },
    data() {
      return {
        timeOpt: {
          start_time: '',
          end_time: ''
        },
        selectKey: 1, // 1时间选择 2活动id 3活动名称 4专题id 5专题名称
        isCheckout: false,
        active: 3,
        nullText: 'noData',
        totalNum: 0,
        dateValue: '',
        searchStr: '',
        timeType: 2,
        loading: true,
        tableList: [],
        tableColumn: [
          {
            label: '活动ID',
            key: 'webinar_id',
            width: '120px'
          },
          {
            label: '活动标题',
            key: 'subject',
            customTooltip: true
          },
          {
            label: '专题名称',
            key: 'subject_title',
            customTooltip: true
          },
          {
            label: '观看人数',
            key: 'total_watch_number',
            width: '120px',
            showHeader: false,
            headerTooltip:
              '人数未进行去重；当观众既观看了直播，又观看了回放时，记为2个观众；当观众观看了直播活动中3个不同的回放，记为3个观众。'
          },
          {
            label: '观看次数',
            key: 'total_watch_times',
            width: '120px'
          },
          {
            label: '观看时长（分）',
            key: 'watch_duration',
            width: '140px'
          }
        ],
        tableRowBtnFun: [
          {
            name: '数据报告',
            methodName: 'dataReport',
            path: '/reportsData',
            index: 100042
          },
          {
            name: '互动统计',
            methodName: 'dataReport',
            path: '/interactionData',
            index: 100043
          },
          {
            name: '用户统计',
            methodName: 'dataReport',
            path: '/userData',
            index: 100044
          }
        ],
        selectOptions: [
          { label: '按活动创建时间', id: 1 },
          { label: '按专题名称', id: 5 },
          { label: '按专题ID', id: 4 },
          { label: '按活动名称', id: 3 },
          { label: '按活动ID', id: 2 }
        ],
        showWatchDurationDatePicker: false,
        watchDuration: [],
        watchDurationPickerOptions: {
          shortcuts: [
            {
              text: '全部',
              onClick(picker) {
                let childrenArray = Array.from(picker.$el.firstChild.firstChild.children);
                childrenArray.forEach(item => {
                  item.style.color = '#666';
                });
                picker.$el.firstChild.firstChild.children[0].style.color = '#FB3A32';
                const end = '';
                const start = '';
                picker.$emit('pick', [start, end]);
              }
            },
            {
              text: '今日',
              onClick(picker) {
                let childrenArray = Array.from(picker.$el.firstChild.firstChild.children);
                childrenArray.forEach(item => {
                  item.style.color = '#666';
                });
                picker.$el.firstChild.firstChild.children[1].style.color = '#FB3A32';
                const end = new Date();
                const start = new Date();
                end.setTime(end.getTime());
                start.setTime(start.getTime());
                picker.$emit('pick', [start, end]);
              }
            },
            {
              text: '近7日',
              onClick(picker) {
                let childrenArray = Array.from(picker.$el.firstChild.firstChild.children);
                childrenArray.forEach(item => {
                  item.style.color = '#666';
                });
                picker.$el.firstChild.firstChild.children[2].style.color = '#FB3A32';
                const end = new Date();
                const start = new Date();
                end.setTime(end.getTime() - 3600 * 1000 * 24);
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit('pick', [start, end]);
              }
            },
            {
              text: '近30日',
              onClick(picker) {
                let childrenArray = Array.from(picker.$el.firstChild.firstChild.children);
                childrenArray.forEach(item => {
                  item.style.color = '#666';
                });
                picker.$el.firstChild.firstChild.children[3].style.color = '#FB3A32';
                const end = new Date();
                const start = new Date();
                end.setTime(end.getTime() - 3600 * 1000 * 24);
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                picker.$emit('pick', [start, end]);
              }
            }
          ],
          // disabledDate是一个函数,参数是当前选中的日期值,这个函数需要返回一个Boolean值,
          disabledDate: time => {
            return time.getTime() > Date.now(); //设置选择今天之前的日期（不能选择当天）
          },
          cellClassName: time => {
            return 'dddd';
          }
        }
      };
    },
    created() {
      this.userId = JSON.parse(sessionOrLocal.get('userId'));
    },
    mounted() {
      // this.$EventBus.$emit('onceQueryDateLiveData')
      // 时间空间和搜索栏交替使用时，只用回车操作及切换日期时，调用接口获取数据列表
    },
    methods: {
      changeSelect() {
        this.$nextTick(() => {
          this.$EventBus.$emit('changeDateLiveSelect');
        });
      },
      onHandleBtnClick(val) {
        let methodsCombin = this.$options.methods;
        methodsCombin[val.type](this, val);
      },
      searchTimeList(opt) {
        const { data, type } = opt;
        const timeArr = [100571, 100572, 100573, 100574];
        this.timeOpt.start_time = data.time[0];
        this.timeOpt.end_time = data.time[1];
        this.$vhall_paas_port({
          k: timeArr[data.timeType],
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: '',
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        this.getTableList(type);
      },
      searchTableList(opt) {
        const { data } = opt;
        this.searchStr = data.content;
        this.$vhall_paas_port({
          k: 100576,
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: '',
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        this.getTableList('search');
      },
      getTableList(type) {
        const pageInfo = this.$refs.tableList.pageInfo; //获取分页信息
        console.log(1, type);
        // if(!this.watchDuration || this.watchDuration&&!this.watchDuration.length){
        //   this.showWatchDurationDatePicker = false
        // }
        if (type === 'search') {
          pageInfo.pageNum = 1;
          pageInfo.pos = 0;
          console.log(2);
        }
        console.log(3, pageInfo);
        const extraOptions = this.formatParmas();
        const options = {
          ...extraOptions,
          ...pageInfo
        };
        // 观看时间
        if (this.watchDuration && this.watchDuration.length) {
          options.watch_start_time = this.watchDuration[0];
          options.watch_end_time = this.watchDuration[1];
        }
        console.error('watchDuration:', this.watchDuration);
        this.getLiveList(options);
      },
      getLiveList(data) {
        this.loading = true;
        this.$fetch('getActiveDataList', this.$params(data))
          .then(res => {
            this.tableList = res.data.list;
            this.totalNum = res.data.total;
          })
          .catch(error => {
            this.$vhMessage({
              message: `获取活动列表失败,${error.msg}`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      createLive() {
        this.$router.push({ path: '/live/edit' });
      },
      // 导出
      exportCenterData() {
        const extraOptions = this.formatParmas();

        // TODO 观看时间
        // 观看时间
        if (this.watchDuration && this.watchDuration.length) {
          extraOptions.watch_start_time = this.watchDuration[0];
          extraOptions.watch_end_time = this.watchDuration[1];
        }
        this.$fetch('exportWebinar', this.$params({ ...extraOptions }))
          .then(res => {
            this.$vhall_paas_port({
              k: 100575,
              data: {
                business_uid: this.userId,
                user_id: '',
                webinar_id: '',
                refer: '',
                s: '',
                report_extra: {},
                ref_url: '',
                req_url: ''
              }
            });
            this.$vhMessage({
              message: `活动数据导出成功，${
                this.$store.state.permissionValue.custom_download_toast || '请去下载中心下载'
              }`,
              showClose: true,
              // duration: 0,
              type: 'success',
              customClass: 'zdy-info-box'
            });
            this.$EventBus.$emit('saas_vs_download_change');
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || `活动数据导出失败`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      dataReport(that, val) {
        let id = val.rows.webinar_id;
        that.$vhall_paas_port({
          k: val.index,
          data: {
            business_uid: that.userId,
            user_id: '',
            webinar_id: id,
            refer: 3,
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        const routeData = that.$router.resolve({
          path: `/live${val.path}/${id}`,
          query: {
            roomId: val.rows.room_id
          }
        });
        window.open(routeData.href, '_blank');
      },
      formatParmas() {
        const opt = {};
        switch (this.selectKey) {
          case 1:
            opt.start_time = this.timeOpt.start_time;
            opt.end_time = this.timeOpt.end_time;
            break;
          case 2:
            opt.like_webinar_id = this.searchStr;
            break;
          case 3:
            opt.title = this.searchStr;
            break;
          case 4:
            opt.project_id = this.searchStr;
            break;
          case 5:
            opt.project_name = this.searchStr;
            break;
        }
        return opt;
      },
      clearWatchDuration() {
        this.watchDuration = [];
        this.showWatchDurationDatePicker = false;
      },
      watchDurationFocus() {
        console.log('watchDurationFocuswatchDurationFocus');
        try {
          if (!this.watchDuration || this.watchDuration.length === 0) {
            this.$nextTick(() => {
              document
                .querySelector('.watch-duration-popper')
                .querySelector('.vh-picker-panel__sidebar').children[0].style.color = '#FB3A32'; // 全部
              document
                .querySelector('.watch-duration-popper')
                .querySelector('.vh-picker-panel__sidebar').children[1].style.color = '#666';
              document
                .querySelector('.watch-duration-popper')
                .querySelector('.vh-picker-panel__sidebar').children[2].style.color = '#666';
              document
                .querySelector('.watch-duration-popper')
                .querySelector('.vh-picker-panel__sidebar').children[3].style.color = '#666';
            });
          }
        } catch (e) {}
      }
    }
  };
</script>

<style lang="less" scoped>
  .data-live {
    .box-card {
      background: #fff;
      padding: 24px 32px;
      border-radius: 4px;
      .search {
        position: relative;
        margin-bottom: 24px;
        .search-label {
          display: inline-block;
          color: rgba(0, 0, 0, 0.85);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px;
          margin-right: 16px;
        }
        .btn-watch-duration {
          color: #fb2626;
          display: inline-block;
        }
        .vh-data-select {
          width: 160px;
          vertical-align: top;
          margin-right: 16px;
          display: inline-block;
        }
        .data-search,
        .search-input {
          vertical-align: top;
          display: inline-block;
        }
        .search-input {
          width: 218px;
          margin-right: 16px;
        }
        .data-search {
          position: relative;
          .vh-range-editor.vh-input__inner.is-round {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
          .empty-close-icon {
            position: absolute;
            right: -14px;
            font-size: 14px;
            /* color: #bfbfbf; */
            //width: 25px;
            display: inline-block;
            line-height: 32px;
            cursor: pointer;
            top: 0px;
            border-top-right-radius: 20px;
            border: 1px solid #d9d9d9;
            border-bottom-right-radius: 20px;
            width: 30px;
            color: #8c8c8c;
            border-left: 0;
            //&:hover{
            //  border-color: #8c8c8c;
            //}
          }
        }
      }
      .search-tag {
        width: 180px;
      }
    }
    .export-data {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
</style>
<style>
  .vh-tooltip__popper.is-dark {
    max-width: 400px;
  }
</style>
